export function encrypt (str) {
   return Buffer.from(str.replace(/\+/g, '-').replace(/\//g, '_')).toString('base64').replace(/=+$/m, '')
}
export function decrypt (str) {
   return Buffer.from(str.padEnd(str.length + (4 - str.length % 4) % 4, '='), 'base64').toString()
}

export function pastel (count) {
   const clrs = []
   while (clrs.length < count) {
      const R = Math.floor((Math.random() * 127) + 127)
      const G = Math.floor((Math.random() * 127) + 127)
      const B = Math.floor((Math.random() * 127) + 127)
      const rgb = (R << 16) + (G << 8) + B
      const clr = `#${rgb.toString(16)}`
      clrs.indexOf(clr) < 0 && clrs.push(clr)
   }
   return count === 1 ? clrs[0] : clrs
}

export function luminance (color) {
   if (!color) return false
   const rgb = parseInt(color.slice(1), 16)
   const r = (rgb >> 16) & 0xff
   const g = (rgb >> 8) & 0xff
   const b = (rgb >> 0) & 0xff
   const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709
   // return luma > 155 // tooBright()
   return luma
}

export function flatten (obj) {
   var toReturn = { }
   for (var key in obj) {
      if (!Object.prototype.hasOwnProperty.call(obj, key)) continue
      if (['Face', 'View', 'Logo', 'Card', 'Sign', 'File', 'Code', 'Form'].includes(key)) {
         toReturn[key] = obj[key].length ? obj[key][0] : ''
      } else if ((typeof obj[key]) === 'object' && obj[key] !== null) {
         var flatObject = flatten(obj[key])
         for (var ndx in flatObject) {
            if (!Object.prototype.hasOwnProperty.call(flatObject, ndx)) continue
            toReturn[ndx] = flatObject[ndx]
         }
      } else toReturn[key] = obj[key]
   }
   return toReturn
}

export function cleanUp (obj) {
   Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
         const child = cleanUp(obj[key])
         if (child === undefined) {
            delete obj[key]
         }
      } else if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
         delete obj[key]
      }
   })
   return Object.keys(obj).length > 0 || obj instanceof Array ? obj : undefined
}

export function imageFromUrl (image) {
   return image.length === 21 ? `/images/${image.substr(0, 11).replace(/\./g, '/')}${image.slice(-10)}` : image
}

export function resizeWidthOrHeight (json) {
   if (json.width > json.height) {
      if (json.width > json.size) {
         json.height *= json.size / json.width
         json.width = json.size
      }
   } else {
      if (json.height > json.size) {
         json.width *= json.size / json.height
         json.height = json.size
      }
   }
}

export function resizeImageToBase64 (image, width, height, format = 'image/jpeg', quality = 0.6) {
   var canvas = document.createElement('canvas')
   canvas.width = width
   canvas.height = height
   canvas.getContext('2d').drawImage(image, 0, 0, width, height)
   return canvas.toDataURL(format, quality)
}

export function blobImageToBase64 (blob, size = 800, format = 'image/png', quality = 0.8) {
   var promise = new Promise(function (resolve, reject) {
      var reader = new FileReader()
      reader.onload = () => {
         var image = new Image()
         image.onload = () => {
            var max = { width: image.width, height: image.height, size: size }
            resizeWidthOrHeight(max)
            var base64 = resizeImageToBase64(image, max.width, max.height, format, quality)
            // console.log(`${event.target.alt}: ${base64.substr(0, 32)}....`)
            resolve(base64)
         }
         image.src = reader.result
      }
      reader.readAsDataURL(blob)
  })
  return promise
}
