import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'
import { flatten, cleanUp, encrypt, decrypt } from '@/utils/nirtara'

Vue.use(Vuex)

const axios = Axios.create({
   // baseURL: `${location.protocol}//${location.hostname}:${location.protocol === 'https:' ? 8143 : 8180}`,
   baseURL: (location.hostname === 'localhost' || location.hostname.match(/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/)) ? `${location.protocol}//${location.hostname}:${location.protocol === 'https:' ? 8143 : 8180}` : '/',
   headers: { 'Content-type': 'application/json' },
})

export default new Vuex.Store({
   state: {
      zone: '',
      user: JSON.parse(decrypt(localStorage.getItem('nvm50web') || 'e30')),
      note: '',
      setting: { global: { Logo: '', kind: 'Global', name: 'NVM-50-WEB', title: 'IoT Visitor Management', copyright: 'NIRTARA' } }, // NGlobalForm.vue
      ipV4: '',
      process: '',
   },
   mutations: {
      zoneDomain (state, payload) {
         state.zone = payload
      },
      authPerson (state, payload) {
         state.user = payload
      },
      snackNotification (state, payload) {
         state.note = payload.Message[0].note // payload.Message[0].catch || payload.Message[0].note
      },
      resetNotification (state) {
         state.note = ''
      },
      'x-forwarded-for' (state, ipV4) {
         state.ipV4 = ipV4
      },
   },
   getters: {
      isLoggedIn: state => !!state.user.id,
      hasMessage: state => !!state.note,
   },
   actions: {
      signin ({ commit, state }, json) {
         return new Promise((resolve) => {
            var data = { conditions: { site: this._vm.$site, kind: json.kind, zone: state.zone, object: { email: json.credential, password: json.password } } }
            var config = { headers: { command: 'signinPerson' } }
            axios.post('/nvm50web', data, config)
            .then(response => {
               if (response.data.Message) resolve(response.data)
               else {
                  // console.log('signin (response.data)', JSON.parse(JSON.stringify(response.data)))
                  const result = flatten(response.data)
                  // console.log('signin (result)', JSON.parse(JSON.stringify(result)))
                  commit('authPerson', result)
                  if (result.id) {
                     localStorage.setItem('nvm50web', encrypt(JSON.stringify(result)))
                     resolve({ Route: [], name: 'Dashboard' })
                  } else {
                     localStorage.removeItem('nvm50web')
                     resolve({ Message: [{ Warning: [], note: 'Credential and/or password did not matched' }], kind: 'Warning' })
                  }
               }
            })
            .catch(error => {
               // console.log('error:', error.response ? error.response.data : error)
               resolve({ data: { Message: [{ Error: [], note: error.message }], kind: 'Error' } })
            })
         })
      },
      logout ({ commit }) {
         return new Promise((resolve, reject) => {
            localStorage.removeItem('nvm50web')
            commit('authPerson', {})
            resolve()
         })
      },
      mongoose ({ state }, json) {
         json.headers && (state.process = json.headers.command)
         // console.log('mongoose (json)', JSON.parse(JSON.stringify(json)))
         var conditions, projection, options, aggregate, update, spread
         if (json.update || json.delete) {
            if (json.update) {
               update = json.update
               !update.site && (update.site = this._vm.$site)
               !update.zone && (update.zone = state.zone)
            }
            // --------
            if (json.delete) {
               !json.delete.site && (json.delete.site = this._vm.$site)
               !json.delete.zone && (json.delete.zone = state.zone)
            }
            // --------
            spread = json.spread ?? {}
            !spread.zone && (spread.zone = state.zone)
         } else if (json.import) {
            json.import.map(j => {
               !j.site && (j.site = this._vm.$site)
               !j.zone && (j.zone = state.zone)
               // console.log('mongoose (j)', JSON.parse(JSON.stringify(j)))
            })
            // --------
            spread = json.spread ?? {}
            !spread.zone && (spread.zone = state.zone)
         } else if (json.aggregate) {
            aggregate = json.aggregate
            aggregate.map(j => {
               if (!j.$match) return
               !j.$match.site && (j.$match.site = this._vm.$site)
               !j.$match.zone && (j.$match.zone = state.zone)
               j.$match.zone === '*' && delete j.$match.zone
            })
            // console.log('mongoose (aggregate)', JSON.parse(JSON.stringify(aggregate)))
         } else {
            conditions = json.conditions ?? {}
            !conditions.site && (conditions.site = this._vm.$site)
            !conditions.zone && (conditions.zone = state.zone)
            conditions.zone === '*' && delete conditions.zone
            // --------
            projection = json.projection ?? {}
            Object.keys(projection).length && Object.assign(projection, { _id: 0 }) // , id: { $toString: '$_id' }
            // --------
            options = json.options ?? {}
            !options.sort && (options.sort = { updatedAt: -1, _id: -1 })
            !options.limit && (options.limit = 60)
            // !options.skip && (options.skip = 0)
         }
         const data = { conditions, projection, options, aggregate, update, spread, delete: json.delete, import: json.import }
         // console.log('mongoose (data)', JSON.parse(JSON.stringify(data)))
         const config = { headers: json.headers }
         return new Promise((resolve) => {
            axios.post('/nvm50web', cleanUp(data), config).then(response => resolve(response)).finally(() => { state.process = '' })
         })
      },
      upload ({ state }, array) {
         state.process = 'uploadBase64'
         return new Promise((resolve) => {
            axios.post('/nvm50web', array, { headers: { command: 'uploadBase64' } }).then(response => resolve(response)).finally(() => { state.process = '' })
         })
      },
      settings ({ commit, state }) {
         // console.log(`settings( hostname = ${location.hostname} )`)
         // const tld = location.hostname.replace(/^[^.]+\./g, '')
         return new Promise((resolve) => {
            axios.post('/nvm50web',
               {
                  // conditions: { kind: 'Management', site: this._vm.$site, $or: [{ 'object.domain': { $regex: `.*${tld}` } }, { 'object.domain': this._vm.$site }] },
                  conditions: { kind: 'Management', site: this._vm.$site, $or: [{ 'object.domain': location.hostname }, { 'object.domain': this._vm.$site }] },
                  lookup: { kind: { $in: ['Global'] } },
               },
               { headers: { command: 'settingOption' } },
            ).then(response => {
               const management = response.data.find(j => j.domain === location.hostname) || response.data[0]
               commit('zoneDomain', management.zone)
               management.Kinds.forEach(json => {
                  const key = json.kind.charAt(0).toLowerCase() + json.kind.slice(1)
                  switch (key) {
                     default: state.setting[key] && Object.assign(state.setting[key], flatten(json))
                        break
                  }
               })
               resolve(management.zone)
            })
         })
      },
   },
})
