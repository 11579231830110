// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

Vue.use(Router)

const router = new Router({
   mode: 'history',
   base: process.env.BASE_URL,
   scrollBehavior: (to, from, savedPosition) => {
      if (to.hash) return { selector: to.hash }
      if (savedPosition) return savedPosition

      return { x: 0, y: 0 }
   },
   routes: [
      {
         path: '/tenant/',
         component: () => import('@/layouts/user/Index.vue'),
         children: [
            {
               path: '',
               name: 'Approval',
               component: () => import('@/views/tenants/Index.vue'),
            },
            {
               path: ':telegram',
               name: 'Subscribe',
               component: () => import('@/views/tenants/Update.vue'), // Web Mobile
            },
         ],
      },
      {
         path: '/visitor/',
         component: () => import('@/layouts/user/Index.vue'),
         children: [
            {
               path: '',
               name: 'Registration',
               component: () => import('@/views/visitors/Update.vue'), // Web Mobile
               // alias: [':email', 'invitation/:email'],
            },
            {
               // path: 'invitation/:identity', // ':badge', badge is identity (':identity'), eg. V0123
               path: 'invitation/:email', // https://192.168.1.101:8100/visitor/invitation/amVzc2ljYS5uaXJ0YXJhQGdtYWlsLmNvbQ
               name: 'Invitation',
               component: () => import('@/views/visitors/Update.vue'), // Web Mobile
            },
            {
               path: 'assistance',
               name: 'Assistance',
               component: () => import('@/views/visitors/Index.vue'),
            },
         ],
      },
      {
         path: '/',
         component: () => import('@/layouts/home/Index.vue'),
         children: [
            {
               path: '',
               name: 'Home',
               component: () => import('@/views/home/Index.vue'),
            },
            {
               path: 'persons',
               name: 'Persons',
               component: () => import('@/views/persons/Index.vue'),
               meta: { requiresAuth: true },
            },
            {
               path: 'locations',
               name: 'Locations',
               component: () => import('@/views/locations/Index.vue'),
               meta: { requiresAuth: true },
            },
            {
               path: 'subjects',
               name: 'Subjects',
               component: () => import('@/views/subjects/Index.vue'),
               meta: { requiresAuth: true },
            },
            {
               path: 'activities',
               name: 'Activities',
               component: () => import('@/views/activities/Index.vue'),
               meta: { requiresAuth: true },
            },
            {
               path: 'profile',
               name: 'Profile',
               component: () => import('@/views/home/Index.vue'),
               meta: { requiresAuth: true },
            },
            {
               path: 'settings',
               name: 'Settings',
               component: () => import('@/views/settings/Index.vue'),
               meta: { requiresAuth: true },
            },
            {
               path: 'dashboard',
               name: 'Dashboard',
               component: () => import('@/views/dashboard/Index.vue'),
               meta: { requiresAuth: true },
            },
            {
               path: 'reports',
               name: 'Reports',
               component: () => import('@/views/reports/Index.vue'),
               meta: { requiresAuth: true },
            },
            {
               path: '*',
               name: 'FourOhFour',
               component: () => import('@/views/404/Index.vue'),
            },
         ],
      },
   ],
})

router.beforeEach((to, from, next) => {
   if (to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.isLoggedIn) next()
      else next('/')
   } else next()
})

export default router
