import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins'
import moment from 'moment'
import io from 'socket.io/client-dist/socket.io'
import excelJS from 'exceljs'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

pdfMake.vfs = pdfFonts.pdfMake.vfs
excelJS.autoWidth = (worksheet, minimalWidth = 4) => {
   worksheet.columns.forEach(column => {
      let maxColumnLength = 0
      column.eachCell({ includeEmpty: true }, (cell) => {
         maxColumnLength = Math.max(maxColumnLength, minimalWidth, cell.value ? cell.value.toString().length : 0)
      })
      column.width = maxColumnLength + 4
   })
}
excelJS.numberToLetters = (number) => {
   let letters = ''
   while (number >= 0) {
      letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[number % 26] + letters
      number = Math.floor(number / 26) - 1
   }
   return letters
}

Vue.config.productionTip = false

moment.locale('id')
Vue.prototype.$moment = moment
Vue.prototype.$pdfMake = pdfMake
Vue.prototype.$excelJS = excelJS
Vue.prototype.$hasRole = (role, auth) => store.state.user[role] && auth.split('').some(a => store.state.user[role].indexOf(a) !== -1)
Vue.prototype.$site = 'nvm.nirtara.com'

store.dispatch('settings').then(zone => {
   Vue.prototype.$socket = io({ path: '/nirtara', query: { zone: zone, client: `${crypto.getRandomValues(new Uint32Array(1)).toString().padStart(10, '0')}-${moment().format('YYYYMMDDHHmmssSSS')}` } })
})
Vue.prototype.$waitSetting = (callback) => {
   if (store.state.zone) callback(store.state.zone)
   else {
      const unwatch = store.watch(
         state => state.zone,
         zone => {
            if (!zone) return
            unwatch && unwatch()
            setTimeout(() => callback(zone), 1)
         },
         { immediate: true },
      )
   }
}

new Vue({
   router,
   store,
   vuetify,
   render: h => h(App),
}).$mount('#app')
